<template>
    <DialogPortal to="#root">
        <DialogOverlay
            class="fixed inset-0 z-50 grid place-items-center overflow-y-auto bg-gray-500/75 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0">
            <DialogContent :class="dialogContentClass" v-bind="forwarded" @pointer-down-outside="pointerDownHandler">
                <slot />
                <DialogClose class="absolute top-0 right-0 p-1 pr-2 pt-2 rounded-tr-md hover:bg-gray-100" />
            </DialogContent>
        </DialogOverlay>
    </DialogPortal>
</template>
<script setup lang="ts">
import { type HTMLAttributes, computed } from "vue";
import {
    DialogContent,
    type DialogContentEmits,
    type DialogContentProps,
    DialogOverlay,
    DialogPortal,
    useForwardPropsEmits,
} from "radix-vue";

const props = defineProps<DialogContentProps & { id?: HTMLAttributes["id"]; class?: HTMLAttributes["class"] }>();
const emits = defineEmits<DialogContentEmits>();

const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props;
    return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);

const DIALOG_CONTENT_CLASS =
    "relative z-50 grid w-full max-w-lg my-8 gap-4 border border-gray-200 bg-white p-6 shadow-lg duration-200 sm:rounded-lg md:w-full";
const dialogContentClass = computed(() => cn(DIALOG_CONTENT_CLASS, props.class));

const pointerDownHandler = (event: CustomEvent<{ originalEvent: PointerEvent }>) => {
    const originalEvent = event.detail.originalEvent;
    const target = originalEvent.target as HTMLElement;
    if (originalEvent.offsetX > target.clientWidth || originalEvent.offsetY > target.clientHeight) {
        event.preventDefault();
    }
};
</script>
